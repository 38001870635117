import React, { useRef, useState } from "react";
import { Box, Button, Chip, DialogActions, DialogTitle, FormControl, InputBase, Menu, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Add, Close, Code, Edit, FileDownload, History, UploadFile } from "@mui/icons-material";
import { putChat, removeChat, setSelectedChatID } from "../../redux/voice-bot/chat-history.slice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { addSnackbar } from "../../redux/snackbars/snackbars.slice";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { JsonEditor } from "../../components/json-editor";
import { ChatMessage } from "./types";

// @todo ideas
// - Parse JSON from the Voice Bot Router / Call History (chatgpt can do it for now)
// - Edit message button

export const SavedChatMenu = ({ handleAddChat }: { handleAddChat: () => void }) => {
    const dispatch = useAppDispatch();
    const { chats, selectedChatID } = useAppSelector((chatHistory) => chatHistory.chatHistory);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [editingChatId, setEditingChatId] = useState<string | null>(null);
    const [newLabel, setNewLabel] = useState<string>("");
    const [jsonDialogOpen, setJsonDialogOpen] = useState<boolean>(false);
    const [chatMessagesForEditing, setChatMessagesForEditing] = useState<ChatMessage[]>([]);
    const [selectedChatForEditing, setSelectedChatForEditing] = useState<string | null>(null);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
        setEditingChatId(null);
        setNewLabel("");
    };

    const handleChatSelect = (chatId: string) => {
        dispatch(setSelectedChatID(chatId));
        closeMenu();
    };

    const handleStartEdit = (id: string, label: string) => {
        setEditingChatId(id);
        setNewLabel(label);
    };

    const handleSaveEdit = () => {
        if (editingChatId) {
            dispatch(putChat({ id: editingChatId, label: newLabel }));
            setEditingChatId(null);
            setNewLabel("");
        }
    };

    const handleDeleteChat = (id: string) => {
        dispatch(removeChat(id));
    };

    const handleDownloadChat = (id: string) => {
        const chat = chats.find((chat) => chat.id === id);
        if (!chat) return;

        const json = JSON.stringify(chat, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const link = document.createElement("a");

        link.href = URL.createObjectURL(blob);
        link.download = `${chat.label || "chat"}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const data = JSON.parse(e.target?.result as string);
                if (data.id && data.label && Array.isArray(data.messages)) {
                    dispatch(putChat(data));
                } else {
                    dispatch(
                        addSnackbar({
                            color: "warning",
                            title: "Invalid chat file. Please upload a valid chat JSON.",
                        })
                    );
                }
            } catch (error) {
                dispatch(
                    addSnackbar({
                        color: "error",
                        title: "Error parsing file. Make sure it is a valid JSON format.",
                    })
                );
            }
        };
        reader.readAsText(file);
    };

    const openJsonEditor = (id: string) => {
        const chat = chats.find((chat) => chat.id === id);
        if (!chat) return;

        setSelectedChatForEditing(id);
        setChatMessagesForEditing(chat.messages);
        setJsonDialogOpen(true);
    };

    const handleJsonSave = () => {
        if (!selectedChatForEditing) return;

        const chat = chats.find((chat) => chat.id === selectedChatForEditing);
        if (!chat) return;

        dispatch(putChat({ id: selectedChatForEditing, messages: chatMessagesForEditing }));
        setJsonDialogOpen(false);
    };

    return (
        <div>
            {/* Hidden File Input */}
            <input
                type="file"
                accept="application/json"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileUpload}
            />

            {/* Chat History Menu Button */}
            <IconButton onClick={openMenu}>
                <History />
            </IconButton>

            {/* Upload Button */}
            <IconButton onClick={handleUploadClick}>
                <UploadFile />
            </IconButton>

            {/* JSON Editor Button */}
            <IconButton onClick={() => openJsonEditor(selectedChatID)} disabled={!selectedChatID}>
                <Code />
            </IconButton>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                {chats.length > 0 ? (
                    chats.map(({ id, label, messages }) => (
                        <MenuItem key={id} selected={id === selectedChatID} onClick={() => handleChatSelect(id)}>
                            {editingChatId === id ? (
                                <InputBase
                                    value={newLabel}
                                    onChange={(e) => setNewLabel(e.target.value)}
                                    onBlur={handleSaveEdit}
                                    autoFocus
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSaveEdit();
                                        }
                                    }}
                                />
                            ) : (
                                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                    <Typography
                                        onDoubleClick={() => handleStartEdit(id, label)}
                                        sx={{ flexGrow: 1, mr: 2 }}
                                    >
                                        {label}
                                    </Typography>
                                    <Tooltip title="Number of messages">
                                        <Chip size="small" label={`${messages.length}`} sx={{ ml: 1, mr: 1 }} />
                                    </Tooltip>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleStartEdit(id, label);
                                        }}
                                    >
                                        <Edit fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteChat(id);
                                        }}
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDownloadChat(id);
                                        }}
                                    >
                                        <FileDownload fontSize="small" />
                                    </IconButton>
                                </Box>
                            )}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>No saved chats</MenuItem>
                )}
                <MenuItem onClick={handleAddChat}>
                    <IconButton size="small">
                        <Add />
                    </IconButton>
                    <Typography>Add New Chat</Typography>
                </MenuItem>
            </Menu>

            {/* JSON Editor Dialog */}
            <Dialog open={jsonDialogOpen} onClose={() => setJsonDialogOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Edit Messages</DialogTitle>
                <DialogContent>
                    <FormControl sx={{ width: "100%" }}>
                        <JsonEditor onChange={setChatMessagesForEditing} value={chatMessagesForEditing} />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setJsonDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleJsonSave} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
