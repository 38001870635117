import React from "react";
import { Box, Breadcrumbs, Container, Link, Typography } from "@mui/material";

interface HeaderPropsInterface {
    title: string;
    breadcrumbs?: { label: string; to: string }[];
    actions?: (JSX.Element | undefined)[];
}

export function Header({ title, breadcrumbs, actions }: HeaderPropsInterface) {
    return (
        <Container
            maxWidth={false}
            sx={{
                mt: 2,
                mb: 3,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        sx={{
                            mr: 3,
                        }}
                    >
                        <Link underline="hover" color="inherit" href="/">
                            Dashboard
                        </Link>
                        {(breadcrumbs || []).map(({ to, label }) => (
                            <Link key={to} underline="hover" color="inherit" href={to}>
                                {label}
                            </Link>
                        ))}
                        <Typography color="text.primary" fontSize={`1.2em`}>
                            {title}
                        </Typography>
                    </Breadcrumbs>
                </Box>

                <Box sx={{ "& > :not(style)": { ml: 1 } }}>
                    {actions}
                    {/*<Button variant="text" sx={{ml:2}}>Text</Button>*/}
                    {/*<Button variant="contained">Contained</Button>*/}
                    {/*<Button variant="outlined" sx={{ml:2}}>Outlined</Button>*/}
                </Box>
            </Box>
        </Container>
    );
}
