import { AnyAction, configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import { reducer as authReducer } from "./auth/auth.slice";
import { reducer as snackbarsReducer } from "./snackbars/snackbars.slice";
import { reducer as promptReducer } from "./voice-bot/prompt.slice";
import { reducer as chatHistoryReducer } from "./voice-bot/chat-history.slice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        snackbars: snackbarsReducer,
        prompt: promptReducer,
        chatHistory: chatHistoryReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
