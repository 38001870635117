import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { I2XLogo } from "components/logo";
import { theme } from "styles/theme";
import { t } from "i18n/translates";
import Alert from "@mui/material/Alert";
import { loginUser } from "redux/auth/actions/auth.login.action";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../util/constants";
import { DialogContent, DialogTitle, Link } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { logout, setDisplayLoginDialog } from "redux/auth/auth.slice";

export function LoginPage() {
    const { userInfo } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (userInfo.role !== ROLES.GUEST) {
            navigate("/");
        }
    }, [userInfo, navigate]);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <I2XLogo />
                    <Login />
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export function LoginDialog() {
    const dispatch = useAppDispatch();
    const { displayLoginDialog } = useAppSelector((s) => s.auth);
    const close = () => dispatch(setDisplayLoginDialog(false));

    window.loginDialog = () => {
        if (!displayLoginDialog) {
            dispatch(logout());
            dispatch(setDisplayLoginDialog(true));
        }
    };

    return (
        <Dialog open={displayLoginDialog} onClose={close}>
            <DialogTitle>Login</DialogTitle>
            <DialogContent>
                <Login />
            </DialogContent>
        </Dialog>
    );
}

export function Login() {
    const { error, loading } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (!data.get("email") || !data.get("password")) {
            return;
        }

        dispatch(
            loginUser({
                email: data.get("email") as string,
                password: data.get("password") as string,
            })
        );
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            {!!error && (
                <Alert severity="error" sx={{ mb: 2, mt: 2 }}>
                    {error}
                </Alert>
            )}

            <TextField
                margin="normal"
                type="email"
                required
                fullWidth
                id="email"
                label={t("login.email_address")}
                name="email"
                autoComplete="email"
                autoFocus
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("login.password")}
                type="password"
                id="password"
                autoComplete="current-password"
            />
            <Link underline="hover" color="inherit" href="/recovery">
                {t("login.forgot_pass")}
            </Link>

            <Button type="submit" fullWidth variant="contained" disabled={loading} sx={{ mt: 3, mb: 2 }}>
                {loading ? <span>{t("common.loading")}</span> : <span>{t("login.sign_in")}</span>}
            </Button>
        </Box>
    );
}
