import { useEffect, useState } from "react";
import { chatBotDefaultOptions } from "./constants";
import { VoiceBotOptions } from "./types";

const useLocalStorage = (key: string, initialValue: VoiceBotOptions) => {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : initialValue;
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};

export const useChatBotContext = (): [VoiceBotOptions, (newOptions: Partial<VoiceBotOptions>) => void] => {
    const [options, set] = useLocalStorage("chatBotOptions", chatBotDefaultOptions);
    const setOptions = (newOptions: Partial<VoiceBotOptions>) => {
        set({
            ...options,
            ...newOptions,
        });
    };

    return [options, setOptions];
};
