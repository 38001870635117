import EventEmitter from "eventemitter3";
import { OpusDecoder } from "opus-decoder";
import { Player } from "./Player";
import { ChatMessage, VoiceBotOptions } from "./types";
import { Recorder } from "./Recorder";
import voiceProto from "./voice-bot.proto";
import { loadToken } from "../../util/token";

type VoiceChatEvent = "started" | "stopped";
type MessageHandler = (message: ChatMessage) => void;

export const VOICE_CHAT_EVENTS: Record<string, VoiceChatEvent> = {
    STARTED: "started",
    STOPPED: "stopped",
};

export class VoiceChat {
    private audioQueue: Array<Uint8Array> = [];
    private options: VoiceBotOptions;
    private endpoint: string;
    private addMessage: MessageHandler;
    private bus: EventEmitter;
    private decoder: OpusDecoder;
    private ws?: WebSocket;
    private player?: Player;
    private recorder?: Recorder;

    constructor(endpoint: string, options: VoiceBotOptions, addMessage: MessageHandler) {
        this.endpoint = endpoint;
        this.addMessage = addMessage;
        this.bus = new EventEmitter();
        this.decoder = new OpusDecoder({ forceStereo: true });
        this.options = options;
    }

    setOptions(options: VoiceBotOptions) {
        this.options = options;
    }

    setEndpoint(endpoint: string) {
        this.endpoint = endpoint;
    }

    on(event: VoiceChatEvent, fn: () => void): void {
        this.bus.on(event, fn);
    }

    start(): void {
        let voice = "";
        if (this.options.voice) {
            voice += `&voice=${this.options.voice}`;
            if (this.options.speed) {
                voice += `:${this.options.speed}`;
                if (this.options.style) {
                    voice += `:${this.options.style}`;
                }
            }
        }

        let vad = "";
        if (this.options.vad) {
            vad = `&vad=${this.options.vad}`;
        }

        this.ws = new WebSocket(`${this.endpoint}?lang=${this.options.lang}${voice}${vad}`);
        this.ws.onmessage = this.handleWebSocketMessage.bind(this);
        this.ws.onopen = () => {
            console.log("WebSocket connection established");

            if (this.ws) {
                const { jwtToken } = loadToken();

                const authRequest = voiceProto.voice.Auth.create({
                    token: jwtToken,
                });
                this.ws.send(voiceProto.voice.Auth.encode(authRequest).finish());
            }
        };
        this.ws.onclose = () => console.log("WebSocket connection closed");
        this.ws.onerror = (error) => console.error("WebSocket error", error);

        this.recorder = new Recorder((audioBlob) => {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                const request = voiceProto.voice.Request.create({ audio: { audio: audioBlob } });
                this.ws.send(voiceProto.voice.Request.encode(request).finish());
            }
        });

        this.recorder.start();
        this.bus.emit(VOICE_CHAT_EVENTS.STARTED);
    }

    private handleWebSocketMessage(event: MessageEvent): void {
        if (!event.data) return;
        event.data
            .arrayBuffer()
            .then((buffer: any) => {
                const response = voiceProto.voice.Response.decode(new Uint8Array(buffer));

                if (response.transcript) {
                    this.addMessage({ isUser: true, text: response.transcript.text });
                } else if (response.reply) {
                    this.addMessage({ isUser: false, text: response.reply.text });
                } else if (response.audio) {
                    this.audioQueue.push(response.audio.audio);
                    this.playAudio();
                }
            })
            .catch(console.error);
    }

    stop(): void {
        this.stopAudio();
        this.recorder?.stop();
        this.recorder = undefined;

        if (this.ws) {
            this.ws.close();
            this.ws = undefined;
        }

        this.bus.emit(VOICE_CHAT_EVENTS.STOPPED);
    }

    private playAudio(): void {
        if (this.audioQueue.length === 0) return;

        const opusFrame = this.audioQueue.shift()!;
        const { channelData, samplesDecoded, sampleRate } = this.decoder.decodeFrame(opusFrame);

        if (samplesDecoded > 0) {
            const pcmSamples = channelData[0];
            if (!this.player) {
                this.player = new Player({
                    channels: 1,
                    sampleRate,
                    flushingTime: 1000,
                });
            }
            this.player.feed(pcmSamples);
        }
        this.playAudio();
    }

    private stopAudio(): void {
        if (this.player) {
            this.player.destroy();
            this.player = undefined;
            this.decoder.reset().catch(console.error);
        }
        this.audioQueue.length = 0;
    }
}
