import { default as OpusRecorder } from "opus-recorder";

export class Recorder {
    private mediaRecorder?: any;
    private stream?: MediaStream;
    private encoderPath: string = "/encoderWorker.min.js";
    private onAudioData?: (audioBlob: Uint8Array) => void;

    constructor(onAudioData: (audioBlob: Uint8Array) => void) {
        this.onAudioData = onAudioData;
    }

    async start(): Promise<void> {
        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.startRecording();
        } catch (error) {
            console.error("Error accessing microphone", error);
        }
    }

    private startRecording(): void {
        if (!this.stream) return;

        const audioCtx = new AudioContext();
        const source = audioCtx.createMediaStreamSource(this.stream);

        this.mediaRecorder = new OpusRecorder({
            sourceNode: source,
            encoderApplication: 2048,
            encoderFrameSize: 60,
            encoderSampleRate: 16000,
            originalSampleRateOverride: 16000,
            maxFramesPerPage: 4,
            streamPages: true,
            encoderPath: this.encoderPath,
        });

        this.mediaRecorder.ondataavailable = (audioBlob: any) => {
            if (audioBlob.length > 0 && this.onAudioData) {
                this.onAudioData(audioBlob);
            }
        };

        this.mediaRecorder.start().catch(console.error);
    }

    stop(): void {
        if (this.stream) {
            this.stream.getTracks().forEach((track) => track.stop());
            this.stream = undefined;
        }

        if (this.mediaRecorder) {
            this.mediaRecorder.close();
            this.mediaRecorder = undefined;
        }
    }
}
