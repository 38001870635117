import React, { useState } from "react";
import { Box, Button, IconButton, InputBase, Menu, MenuItem, Typography } from "@mui/material";
import { Add, Close, Edit, ListAlt } from "@mui/icons-material";

type Version = {
    id: string;
    label: string;
};

export const PromptVersionMenu = ({
    versions,
    activeVersionId,
    handleSelectVersion,
    handleDeleteVersion,
    handleSaveLabel,
    handleAddVersion,
}: {
    versions: Version[];
    activeVersionId: string | null;
    handleSelectVersion: (id: string) => void;
    handleDeleteVersion: (id: string) => void;
    handleSaveLabel: (id: string, newLabel: string) => void;
    handleAddVersion: () => void;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [editingLabelId, setEditingLabelId] = useState<string | null>(null);
    const [newLabel, setNewLabel] = useState<string>("");

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleStartEdit = (id: string, label: string) => {
        setEditingLabelId(id);
        setNewLabel(label);
    };

    const handleSaveEdit = () => {
        if (editingLabelId) {
            handleSaveLabel(editingLabelId, newLabel);
            setEditingLabelId(null);
            setNewLabel("");
        }
    };

    return (
        <div>
            <Button onClick={openMenu} variant="outlined" startIcon={<ListAlt />}>
                Select Version
            </Button>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                {versions.map((version) => (
                    <MenuItem
                        key={version.id}
                        selected={version.id === activeVersionId}
                        onClick={() => {
                            handleSelectVersion(version.id);
                            closeMenu();
                        }}
                    >
                        {editingLabelId === version.id ? (
                            <InputBase
                                value={newLabel}
                                onChange={(e) => setNewLabel(e.target.value)}
                                onBlur={handleSaveEdit}
                                autoFocus
                                sx={{ fontSize: "0.9rem" }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSaveEdit();
                                    }
                                }}
                            />
                        ) : (
                            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                <Typography
                                    onDoubleClick={() => handleStartEdit(version.id, version.label)}
                                    sx={{ flexGrow: 1, mr: 2 }}
                                >
                                    {version.label}
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleStartEdit(version.id, version.label);
                                    }}
                                >
                                    <Edit fontSize="small" />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteVersion(version.id);
                                    }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                                {/*<IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        handleSelectVersion(version.id);
                                        closeMenu();
                                    }}
                                >
                                    <FileDownload fontSize="small" />
                                </IconButton>*/}
                            </Box>
                        )}
                    </MenuItem>
                ))}
                <MenuItem onClick={handleAddVersion}>
                    <IconButton size="small">
                        <Add />
                    </IconButton>
                    <Typography>Add New Version</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
};
