import { Lang, VoiceBotOptions, ProjectInterface } from "./types";

export const PROJECT_LIST: ProjectInterface[] = [
    {
        label: "Sales bot (test playground)",
        versionEndpoint: "v1",
        isProtected: false,
    },
    {
        label: "Call Simulator",
        versionEndpoint: "v2",
        isProtected: true,
    },
];

export const languages: Lang[] = [Lang.EN, Lang.DE];

export const voices: Record<string, string> = {
    "": "Default",
    "en-US-AvaMultilingualNeural": "Ava",
    "en-US-NovaTurboMultilingualNeural": "Nova",
    "de-DE-SeraphinaMultilingualNeural": "Seraphina (DE)",
    "en-US-AriaNeural": "Aria (EN only)",
    "en-US-AlloyTurboMultilingualNeural": "Alloy",
};

export const voicesStyles: Record<string, string[]> = {
    "en-US-AriaNeural": [
        "angry",
        "chat",
        "cheerful",
        "customerservice",
        "empathetic",
        "excited",
        "friendly",
        "hopeful",
        "narration-professional",
        "newscast-casual",
        "newscast-formal",
        "sad",
        "shouting",
        "terrified",
        "unfriendly",
        "whispering",
    ],
};

export const chatBotDefaultOptions: VoiceBotOptions = {
    lang: Lang.EN,
    voice: "",
    speed: "1",
    style: "",
    vad: "",
};

export const promptBlockCategories = [
    { label: "Lang Instruction", color: "#1976d2" }, // Instructions related to language, tone, or style
    { label: "Core Topics", color: "#dc004e" }, // Main subjects or themes for the prompt
    { label: "Do Say", color: "#2e7d32" }, // Specific phrases, words, or styles to include
    { label: "Don't Say", color: "#ed6c02" }, // Words, tones, or phrases to avoid
    { label: "Style Vibes", color: "#d32f2f" }, // Descriptions of the style, mood, or formatting
    { label: "Examples Zone", color: "#0288d1" }, // Example inputs or outputs for better context
    { label: "Constraints", color: "#9e9e9e" }, // Limitations like word count, structure, or formats
];

export function getColorByLabel(label: string): string {
    const category = promptBlockCategories.find((cat) => cat.label === label);
    return category ? category.color : "#606060"; // Returns null if label is not found
}
