import React, { useCallback, useEffect, useRef, useState } from "react";
import { VOICE_CHAT_EVENTS, VoiceChat } from "./VoiceChat";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { BotChatMessage, ChatMessage, Lang, ProjectInterface } from "./types";
import { languages, PROJECT_LIST, voices, voicesStyles } from "./constants";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useChatBotContext } from "./context";
import { theme } from "../../styles/theme";
import { Navbar } from "../../components/navbar";
import { Header } from "../../components/header";
import { t } from "../../i18n/translates";
import { ThemeProvider } from "@mui/material/styles";
import { PromptEditor } from "./PromptEditor";
import { generateAnswer, getSimulatorEndpoint } from "./api";
import { ChatContainer } from "./Chat";
import IconButton from "@mui/material/IconButton";
import { Lock, Mic, MicOff, Send } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addSnackbar } from "../../redux/snackbars/snackbars.slice";
import { SavedChatMenu } from "./ChatSavedMenu";
import { emptyChat, putChat, setSelectedChatID } from "redux/voice-bot/chat-history.slice";
import Box from "@mui/material/Box";

export function VoiceBot() {
    const dispatch = useAppDispatch();
    const { chats, selectedChatID } = useAppSelector((state) => state.chatHistory);
    const selectedChat = chats.find((chat) => chat.id === selectedChatID) || emptyChat();

    const [selectedProject, selectProject] = useState<ProjectInterface>(PROJECT_LIST[0]);

    const [options, setOptions] = useChatBotContext();
    const [isLoading, setLoading] = useState(false);
    const [isStarted, setIsStarted] = useState(false);
    const [newMessageText, setNewMessageText] = useState("");

    const updateChatMessages = useCallback(
        (fn: (prev: ChatMessage[]) => ChatMessage[]) => {
            const newChatMessages = fn(selectedChat.messages);

            dispatch(
                putChat({
                    ...selectedChat,
                    messages: newChatMessages,
                })
            );
        },
        [dispatch, selectedChat]
    );

    const handleAddChat = useCallback(() => {
        const newChat = emptyChat();
        dispatch(putChat(newChat));
        dispatch(setSelectedChatID(newChat.id));
    }, [dispatch]);

    const catchError = useCallback(
        (e: Error) => {
            console.error(e);
            dispatch(
                addSnackbar({
                    color: "error",
                    title: e.message,
                })
            );
        },
        [dispatch]
    );

    const sendGenerateAnswer = (messagesPayload: ChatMessage[]) => {
        setLoading(true);
        generateAnswer(
            selectedProject.versionEndpoint,
            options.lang,
            messagesPayload.map(({ isUser, isSystem, text }) => ({
                role: isUser ? "user" : "assistant",
                content: text,
            }))
        )
            .then((messages: BotChatMessage[]) => {
                setNewMessageText("");
                updateChatMessages(() =>
                    messages.map(({ role, content }) => ({
                        isUser: role === "user",
                        isSystem: role === "assistant",
                        text: content,
                    }))
                );
                setLoading(false);
            })
            .catch(catchError);
    };

    const handleSendNewMessage = () => {
        sendGenerateAnswer(
            selectedChat.messages.concat([
                {
                    isUser: true,
                    text: newMessageText,
                },
            ])
        );
    };

    const handleRegenerate = (idx: number) => {
        sendGenerateAnswer(selectedChat.messages.slice(0, idx + 1));
    };

    const voiceChat = useRef(
        new VoiceChat(getSimulatorEndpoint(selectedProject.versionEndpoint), options, (message) => {
            updateChatMessages((m) => m.concat([message]));
        })
    );

    useEffect(() => {
        voiceChat.current.on(VOICE_CHAT_EVENTS.STARTED, () => {
            handleAddChat();
            setIsStarted(true);
        });
        voiceChat.current.on(VOICE_CHAT_EVENTS.STOPPED, () => setIsStarted(false));
    }, [handleAddChat, updateChatMessages]);

    useEffect(() => {
        if (voiceChat.current) {
            voiceChat.current.setOptions(options);
        }
    }, [options, voiceChat]);

    useEffect(() => {
        if (voiceChat.current) {
            voiceChat.current.setEndpoint(getSimulatorEndpoint(selectedProject.versionEndpoint));
        }
    }, [selectedProject.versionEndpoint, voiceChat]);

    return (
        <ThemeProvider theme={theme}>
            <Navbar />
            <Header
                title={t("nav.voice_bot")}
                actions={[
                    <TextField
                        key={"Project"}
                        select
                        style={{ width: "300px" }}
                        label="Project"
                        size="small"
                        onChange={({ target }) => {
                            selectProject(PROJECT_LIST.find((p) => p.label === target.value) || PROJECT_LIST[0]);
                        }}
                        value={selectedProject.label}
                    >
                        {PROJECT_LIST.map((project) => (
                            <MenuItem key={project.label} value={project.label}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            marginRight: "4px",
                                        }}
                                    >
                                        {project.isProtected && (
                                            <Lock
                                                style={{
                                                    width: "16px",
                                                    height: "16px",
                                                    color: "gray",
                                                    display: "block",
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div>{project.label}</div>
                                </Box>
                            </MenuItem>
                        ))}
                    </TextField>,
                ]}
            />
            <Container maxWidth={false} sx={{ mt: 2, mb: 2, height: "calc(100vh - 150px)" }}>
                <Grid container spacing={2} style={{ height: "100%" }}>
                    <Grid item md={6}>
                        <PromptEditor project={selectedProject} lang={options.lang} catchError={catchError} />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                        }}
                    >
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                justifyContent: "space-between",
                                mb: 1,
                            }}
                        >
                            <Grid item>
                                <TextField
                                    select
                                    size="small"
                                    label="VAD"
                                    title="Voice Actividy Detection"
                                    disabled={isStarted}
                                    value={options.vad || ""}
                                    onChange={({ target }) => {
                                        setOptions({
                                            vad: target.value,
                                        });
                                    }}
                                    sx={{
                                        minWidth: "100px",
                                    }}
                                >
                                    {["", 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map((voiceSpeed) => (
                                        <MenuItem key={`v${voiceSpeed}`} value={`${voiceSpeed}`}>
                                            {voiceSpeed || "Default"}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <TextField
                                            select
                                            size="small"
                                            label="Language"
                                            onChange={({ target }) => {
                                                setOptions({
                                                    lang: target.value as Lang,
                                                });
                                            }}
                                            value={options.lang}
                                            disabled={isStarted}
                                        >
                                            {languages.map((lang) => (
                                                <MenuItem key={lang} value={lang}>
                                                    {lang.toUpperCase()}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            select
                                            size="small"
                                            label="Voice"
                                            disabled={isStarted}
                                            value={options.voice}
                                            onChange={({ target }) => {
                                                setOptions({
                                                    voice: target.value,
                                                    style: "",
                                                });
                                            }}
                                            sx={{
                                                minWidth: "100px",
                                            }}
                                        >
                                            {Object.keys(voices).map((voice) => (
                                                <MenuItem key={voice} value={voice}>
                                                    {voices[voice]}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            select
                                            size="small"
                                            label="Speed"
                                            disabled={isStarted}
                                            value={options.speed}
                                            onChange={({ target }) => {
                                                setOptions({
                                                    speed: target.value,
                                                });
                                            }}
                                            sx={{
                                                minWidth: "70px",
                                            }}
                                        >
                                            {[0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((voiceSpeed) => (
                                                <MenuItem key={`v${voiceSpeed}`} value={`${voiceSpeed}`}>
                                                    {voiceSpeed}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            select
                                            size="small"
                                            label="Style"
                                            value={options.style}
                                            onChange={({ target }) => {
                                                setOptions({
                                                    style: target.value,
                                                });
                                            }}
                                            disabled={isStarted || !voicesStyles[options.voice]}
                                            sx={{
                                                minWidth: "100px",
                                            }}
                                        >
                                            {(voicesStyles[options.voice] ?? [""]).map((style) => (
                                                <MenuItem key={style} value={style}>
                                                    {style}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={`Start a new audio conversation`}>
                                            <Button
                                                variant="contained"
                                                onClick={() =>
                                                    isStarted ? voiceChat.current.stop() : voiceChat.current.start()
                                                }
                                                startIcon={isStarted ? <MicOff /> : <Mic />}
                                                disabled={isLoading}
                                            >
                                                {isStarted ? `Stop` : `Start New`}
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <ChatContainer chat={selectedChat} handleRegenerate={handleRegenerate} />

                        <Grid container spacing={1} sx={{ marginTop: 0.5, alignItems: "center" }}>
                            <Grid item>
                                <SavedChatMenu handleAddChat={handleAddChat} />
                            </Grid>
                            <Grid item sx={{ flexGrow: 1 }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    placeholder="Type a message"
                                    value={newMessageText}
                                    disabled={isStarted}
                                    onChange={(e) => setNewMessageText(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSendNewMessage();
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <IconButton
                                    color="primary"
                                    onClick={handleSendNewMessage}
                                    disabled={isLoading || isStarted}
                                >
                                    <Send />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
