import { get } from "lodash-es";

const translates = {
    // : TranslateInterface
    nav: {
        users: "Users",
        teams: "Teams",
        locations: "Locations",
        agencies: "Agencies",
        tenants: "Tenants",
        profile: "Profile",
        logout: "Logout",
        open_settings: "Open settings",
        voice_bot: "Voice Bot",
    },
    common: {
        loading: "Loading",
        cancel: "Cancel",
        confirm: "Confirm",
        loadMore: "Load more",
        loadNext: "Next page",
        loadPrev: "Prev page",
        none: "None",
        search: "Search",
        clear: "Clear",
        info: "Info",
        saved: "Successfully saved",
        save: "Save",
        remove: "Remove",
        clone: "Clone",
        copied: "Copied to clipboard",
        id: "ID",
    },
    login: {
        sign_in: "Sign In",
        sign_in_with_google: "Sign In With Google",
        password: "Password",
        email_address: "Email Address",
        forgot_pass: "Forgot password?",
        recovery: {
            title: "Forgot your password?",
            description:
                "Please enter the email address you used to login. We will send you an email with instructions on how to reset your password.",
            submit: {
                title: "Reset your password",
                in_progress: "Connecting",
            },
            sent_description: "We've sent you an email with a link to reset your password.",
            reset_description: "Set a new password for the account.",
            no_email:
                "If you didn't receive an email: Check if the email is in your spam email box or if the email you entered might be incorrect.",
            password_too_weak: "Password is too weak.",
        },
    },
    users: {
        disassociated: "Disassociated",
        email: "Email",
        first_name: "First name",
        last_name: "Last name",
        system_id: "System ID",
        company_username: "Company username",
        personal_username: "Personal username",
        role: "Role",
        location_id: "Location ID",
        clearance_id: "Clearance ID",
        language_code: "Language code",
        timezone: "Timezone",
        active: "Active",
        superuser: "Superuser",
        staff: "Staff",
        show_anonymized: "Show anonymized",
        vendor_employee: "Vendor employee",
        date_joined: "Date joined",
        last_login: "Last login",
        last_call_at: "Last call at",
        password_changed_at: "Password changed at",
        telephony_id: "Telephony ID",
        team_id: "Team ID",
        tenant_id: "Tenant ID",
        agency_id: "Agency ID",
        user_state: "State",
        overwritten_app_settings: "App settings",
        use_overwritten_app_settings: "Overwrite app settings",
        overwritten_desktop_app_settings: "Desktop app settings",
        use_overwritten_desktop_app_settings: "Overwrite desktop app settings",
        confirm_delete: "Remove user?",
        create_user_title: "Create user",
        update_user_title: "User #{{ID}}",
        id: "ID",
        name: "Name",
        set_pass_description:
            "The longer your password is, the better. Avoid ties to your personal information, such as your name, surname, or date of birth. Use numbers, symbols, and upper- and lowercase letters in random order.",
        set_pass_title: "Change Password",
        resend_welcome: "Resend Welcome Email",
    },
    teams: {
        id: "ID",
        name: "Name",
        location_id: "Location ID",
        manager_id: "Manager ID",
        created_by_id: "Created by ID",
        label: "Label",
        create_team_title: "Create team",
        update_team_title: "Team #{{ID}}",
        confirm_delete: "Remove team?",
        overwritten_desktop_app_settings: "Desktop app settings",
        overwritten_app_settings: "App settings",
        whitelist: "Keywords whitelist",
        blacklist: "Keywords blacklist",
        battlecard: "Battlecard",
        team_members: "Team members",
        team_agents: "Team agents",
        team_managers: "Team managers",
        location_members: "Location members",
        remove_from_team: "Remove from team",
        add_to_team: "Add to team",
    },
    locations: {
        id: "ID",
        name: "Name",
        login_alias: "Login alias",
        agency_id: "Agency ID",
        user_login_token: "User login token",
        user_login_secret: "User login secret",
        language_code: "Language code",
        city_alias: "City alias",
        billing_email: "Billing email",
        phone_number: "Phone number",
        auth_token: "Auth token",
        auth_scheme: "Auth scheme",
        create_location_title: "Create location",
        update_location_title: "Location #{{ID}}",
        confirm_delete: "Remove location?",
        whitelist: "Keywords whitelist",
        blacklist: "Keywords blacklist",
        address: "Address",
        address_street_and_house: "Street and house",
        address_additional_info: "Additional info",
        address_city: "City",
        address_state: "State",
        address_country: "Country",
        address_postal_code: "Postal code",
        app_settings: "App settings",
        desktop_app_settings: "Desktop app settings",
        general_settings: "General settings",
    },
    agencies: {
        id: "ID",
        name: "Name",
        tenant_id: "Tenant ID",
        create_agency_title: "Create agency",
        update_agency_title: "Agency #{{ID}}",
        confirm_delete: "Remove tenant?",
    },
    tenants: {
        id: "ID",
        name: "Name",
        confirm_delete: "Remove tenant?",
        update_tenant_title: "Tenant #{{ID}}",
        create_tenant_title: "Create tenant",
        app_settings: "App settings",
        data_pipeline_settings: "Data Pipeline Settings",
    },
    errors: {
        unknown_error: "Unknown error",
        invalid_email_or_password: "Invalid email or password",
        invalid_auth_token: "Invalid auth token",
        invalid_google_auth_code: "Invalid Google auth code",
        entity_cannot_be_deleted:
            "Unfortunately, this entity cannot be deleted because the database has data associated with it.",
    },
};

type DotPrefix<T extends string> = T extends "" ? "" : `.${T}`;

type DotNestedKeys<T> = (
    T extends object
        ? {
              [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}`;
          }[Exclude<keyof T, symbol>]
        : ""
) extends infer D
    ? Extract<D, string>
    : never;

export const t = (key: DotNestedKeys<typeof translates>, params: { [key: string]: string | number } = {}): string => {
    return Object.keys(params).reduce((current, paramKey) => {
        return current.replaceAll(`{{${paramKey}}}`, `${params[paramKey]}`);
    }, get(translates, key));
};
