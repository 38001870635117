import { useAppDispatch } from "../../redux/hooks";
import { logout } from "../../redux/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function Logout() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(logout());
        navigate("/");
        document.location.reload();
    }, [dispatch, navigate]);

    return null;
}
