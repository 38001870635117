import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PromptBlockInterface, PromptStateInterface, PromptVersionInterface } from "pages/voice-bot/types";
// import { fetchPromptSuggestions, savePromptToBackend } from "pages/voice-bot/api";
import { loadFromLocalStorage, saveToLocalStorage } from "util/helper";
import { v4 as uuidv4 } from "uuid";

const savedVersions: PromptVersionInterface[] = (() => {
    const savedData = loadFromLocalStorage("promptVersions");
    return Array.isArray(savedData) ? savedData : [];
})();
if (savedVersions.length === 0) {
    savedVersions.push({
        id: uuidv4(),
        label: "new",
        content: "",
    });
}

const initialState: PromptStateInterface = {
    blocks: loadFromLocalStorage("promptBlocks") || [],
    savedBlocks: [],
    status: "idle",
    versions: savedVersions,
    activeVersionId: loadFromLocalStorage("promptVersionActiveId") || savedVersions[0].id,
};

// // Async thunk to simulate saving the prompt
// export const savePrompt = createAsyncThunk("prompt/save", async (version: PromptVersionInterface) => {
//     return version;
// });
//
// export const syncPromptBlocks = createAsyncThunk("prompt/sync", async (blocks: PromptBlockInterface[]) => {
//     return await savePromptToBackend(blocks);
// });
//
// export const askAiForPrompt = createAsyncThunk(
//     "prompt/askAI",
//     async (request: { prompt: string; blockContent: string }) => {
//         return await fetchPromptSuggestions(request.prompt, request.blockContent);
//     }
// );

const promptSlice = createSlice({
    name: "prompt",
    initialState,
    reducers: {
        addBlock: (state, action: PayloadAction<PromptBlockInterface>) => {
            state.blocks.push(action.payload);
            saveToLocalStorage("promptBlocks", state.blocks);
        },
        updateBlock: (state, action: PayloadAction<PromptBlockInterface>) => {
            const index = state.blocks.findIndex((block) => block.id === action.payload.id);
            if (index !== -1) {
                state.blocks[index] = action.payload;
                saveToLocalStorage("promptBlocks", state.blocks);
            }
        },
        removeBlock: (state, action: PayloadAction<string>) => {
            state.blocks = state.blocks.filter((block) => block.id !== action.payload);
            saveToLocalStorage("promptBlocks", state.blocks);
        },
        reorderBlocks: (state, action: PayloadAction<PromptBlockInterface[]>) => {
            state.blocks = action.payload;
            saveToLocalStorage("promptBlocks", state.blocks);
        },
        addVersion: (state, action: PayloadAction<PromptVersionInterface>) => {
            state.versions.push(action.payload);
            if (!state.activeVersionId) {
                state.activeVersionId = action.payload.id;
            }
            saveToLocalStorage("promptVersions", state.versions);
            saveToLocalStorage("promptVersionActiveId", state.activeVersionId);
        },
        removeVersion: (state, action: PayloadAction<string>) => {
            state.versions = state.versions.filter((v) => v.id !== action.payload);
            if (state.activeVersionId === action.payload) {
                state.activeVersionId = state.versions.length ? state.versions[0].id : null;
            }
            saveToLocalStorage("promptVersions", state.versions);
            saveToLocalStorage("promptVersionActiveId", state.activeVersionId);
        },
        updateVersion: (state, action: PayloadAction<PromptVersionInterface>) => {
            const index = state.versions.findIndex((v) => v.id === action.payload.id);
            if (index > -1) {
                state.versions[index] = action.payload;
            }
            saveToLocalStorage("promptVersions", state.versions);
            saveToLocalStorage("promptVersionActiveId", state.activeVersionId);
        },
        setActiveVersion: (state, action: PayloadAction<string>) => {
            state.activeVersionId = action.payload;
            saveToLocalStorage("promptVersionActiveId", state.activeVersionId);
        },
    },
});

export const {
    addBlock,
    updateBlock,
    removeBlock,
    reorderBlocks,
    addVersion,
    removeVersion,
    updateVersion,
    setActiveVersion,
} = promptSlice.actions;
export const { reducer } = promptSlice;
