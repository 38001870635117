import React, { useEffect, useRef, useState } from "react";
import { addVersion, removeVersion, setActiveVersion, updateVersion } from "redux/voice-bot/prompt.slice";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { InfoOutlined, UploadFileOutlined } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { applyPrompt, getPrompt } from "./api";
import { addSnackbar } from "redux/snackbars/snackbars.slice";
import { t } from "i18n/translates";
import { theme } from "styles/theme";
import { PromptVersionMenu } from "./PromptVersionMenu";
import { ProjectInterface } from "./types";

// @todo ideas
// - AI request help to improve prompt (use https://github.com/kpdecker/jsdiff)
// - Prompt reusable blocks
// - Auto sync with github repo
// - Saving all this staff to backend (not to the localstorage)

export const PromptEditor = ({
    project,
    lang,
    catchError,
}: {
    project: ProjectInterface;
    lang: string;
    catchError: (e: Error) => void;
}) => {
    const dispatch = useAppDispatch();
    const { versions, activeVersionId } = useAppSelector((state) => state.prompt);
    const [content, setContent] = useState("");
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [deleteTargetId, setDeleteTargetId] = useState<string | null>(null);
    const [showModalInfo, setShowModalInfo] = useState("");

    const handleShowInfo = () => {
        getPrompt(project.versionEndpoint, lang)
            .then(({ prompt }) => {
                setShowModalInfo(prompt);
            })
            .catch(catchError);
    };

    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const activeVersion = versions.find((v) => v.id === activeVersionId);
        setContent(activeVersion?.content || "");
    }, [activeVersionId, versions]);

    const handleAddVersion = () => {
        const id = uuidv4();
        const newVersion = { id, label: `Version ${versions.length + 1}`, content: "" };
        dispatch(addVersion(newVersion));
        dispatch(setActiveVersion(id));
    };

    const handleRemoveVersion = () => {
        if (deleteTargetId) {
            dispatch(removeVersion(deleteTargetId));
            setDeleteTargetId(null);
        }
        setShowDeleteDialog(false);
    };

    const handleChangeTab = (newValue: string) => {
        dispatch(setActiveVersion(newValue));
    };

    const handleApplyPrompt = () => {
        setLoading(true);
        applyPrompt(project.versionEndpoint, lang, content)
            .then(() => {
                setLoading(false);
                dispatch(
                    addSnackbar({
                        color: "success",
                        title: t("common.saved"),
                    })
                );
            })
            .catch(catchError);
    };

    // Auto-save logic with debounce
    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            let updatedVersion = null;
            if (activeVersionId) {
                updatedVersion = versions.find((v) => v.id === activeVersionId);
            }
            dispatch(
                updateVersion({
                    id: activeVersionId || uuidv4(),
                    label: updatedVersion?.label || "Saved Version",
                    content,
                })
            );
        }, 1000);

        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, [content, dispatch, activeVersionId, versions]);

    const handleLabelSave = (editingLabelId: string, newLabel: string) => {
        if (editingLabelId) {
            const updatedVersion = versions.find((v) => v.id === editingLabelId);
            if (updatedVersion) {
                dispatch(updateVersion({ ...updatedVersion, label: newLabel }));
            }
        }
    };

    const openDeleteDialog = (id: string) => {
        setDeleteTargetId(id);
        setShowDeleteDialog(true);
    };

    return (
        <Box sx={{ height: "100%", position: "relative", display: "flex", flexDirection: "column" }}>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    mb: 1,
                }}
            >
                <PromptVersionMenu
                    versions={versions}
                    activeVersionId={activeVersionId}
                    handleSelectVersion={handleChangeTab}
                    handleDeleteVersion={openDeleteDialog}
                    handleSaveLabel={handleLabelSave}
                    handleAddVersion={handleAddVersion}
                />
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    <Button onClick={handleShowInfo} startIcon={<InfoOutlined />} sx={{ mr: 1 }}>
                        Current prompt
                    </Button>

                    <Button
                        onClick={handleApplyPrompt}
                        color="success"
                        variant="contained"
                        disabled={isLoading || project.isProtected}
                        startIcon={<UploadFileOutlined />}
                    >
                        Upload to Bot
                    </Button>
                </Box>
            </Box>

            <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Type your prompt here..."
                style={{
                    height: "100%",
                    flexGrow: "1",
                    borderRadius: theme.shape.borderRadius,
                    border: `1px solid rgba(0, 0, 0, 0.12)`,
                }}
            />

            <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>Are you sure you want to delete this version?</DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
                    <Button onClick={handleRemoveVersion} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={!!showModalInfo} onClose={() => setShowModalInfo("")}>
                <DialogTitle>Current Bot Prompt</DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{
                            overflow: "auto",
                            height: "70vh",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        {showModalInfo}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowModalInfo("")}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default PromptEditor;
