import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatInterface } from "pages/voice-bot/types";
import { uuid } from "../../util/helper";
import dayjs from "dayjs";

export const emptyChat = () => ({
    id: uuid(),
    label: dayjs().format("DD.MM HH:mm"),
    messages: [],
    createdAt: Date.now(),
});

// Utility to load chats from localStorage
const loadChats = (): ChatInterface[] => {
    const data = localStorage.getItem("chat_history");
    return data ? JSON.parse(data) : [];
};

const preSavedChats = loadChats();
if (preSavedChats.length === 0) {
    preSavedChats.push(emptyChat());
}

// Utility to save chats to localStorage
const saveChats = (chats: ChatInterface[]) => {
    localStorage.setItem("chat_history", JSON.stringify(chats));
};

interface ChatHistoryStateInterface {
    chats: ChatInterface[];
    selectedChatID: string;
}

// Initial State
const initialState: ChatHistoryStateInterface = {
    chats: preSavedChats,
    selectedChatID: preSavedChats.reduce((last, current) => (current.createdAt > last.createdAt ? current : last))?.id,
};

// Redux Slice
const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        putChat: (state, action: PayloadAction<Partial<ChatInterface>>) => {
            const chatIdx = !action.payload.id ? -1 : state.chats.findIndex((c) => c.id === action.payload.id);

            if (chatIdx === -1) {
                state.chats.push({
                    ...emptyChat(),
                    ...action.payload,
                });
            } else {
                Object.assign(state.chats[chatIdx], action.payload);
            }

            saveChats(state.chats);
        },
        removeChat: (state, action: PayloadAction<string>) => {
            const index = state.chats.findIndex((chat) => chat.id === action.payload);
            if (index !== -1) {
                state.chats.splice(index, 1);
                saveChats(state.chats);
            }
        },
        setSelectedChatID: (state, action: PayloadAction<string>) => {
            state.selectedChatID = action.payload;
        },
    },
});

// Export actions
export const { setSelectedChatID, putChat, removeChat } = chatSlice.actions;

// Export reducer
export const { reducer } = chatSlice;
