import { apiRequest } from "../../util/api";

export const getBotApiEndpoint = () =>
    localStorage.getItem("__local_bot") ? `http://127.0.0.1:8000` : `https://ml4.i2x.ai`;

export const getBotApiPrefix = (version = "v1") => `/api/chat/${version}`;

// export const savePromptToBackend = async (blocks: PromptBlockInterface[]) => {
//     return apiRequest({
//         method: "put",
//         baseUrl: getBotApiEndpoint(),
//         url: `${getBotApiPrefix()}/settings`,
//         data: {
//             key: "prompts-blocks",
//             data: blocks,
//         },
//     });
// };

export const getPrompt = async (version: string, lang: string) => {
    return apiRequest({
        method: "get",
        baseUrl: getBotApiEndpoint(),
        url: `${getBotApiPrefix(version)}/prompt?lang=${lang}`,
    });
};

export const applyPrompt = async (version: string, lang: string, prompt: string) => {
    return apiRequest({
        method: "put",
        baseUrl: getBotApiEndpoint(),
        url: `${getBotApiPrefix(version)}/prompt`,
        data: {
            lang,
            prompt,
        },
    });
};

export const generateAnswer = async (
    version: string,
    lang: string,
    messages: {
        role: "user" | "assistant";
        content: string;
    }[]
) => {
    return apiRequest({
        method: "post",
        baseUrl: getBotApiEndpoint(),
        url: `${getBotApiPrefix(version)}/generate`,
        data: { messages, lang, context: {} },
    });
};

// export const fetchPromptSuggestions = async (prompt: string, content: string) => {
//     return apiRequest({
//         method: "post",
//         baseUrl: getBotApiEndpoint(),
//         url: `${getBotApiPrefix()}/suggest`,
//         data: { prompt, content },
//     });
// };

export const getSimulatorEndpoint = (version = "v1") =>
    localStorage.getItem("__local_bot")
        ? `ws://127.0.0.1:8082/simulator/${version}`
        : `wss://bot.ml4.i2x.ai/simulator/${version}`;
