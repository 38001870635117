import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "styles/theme";
import { Navbar } from "../../components/navbar";
import { Box, Container } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../redux/auth/auth.selectors";

function DashboardContent() {
    const userInfo = useSelector(selectUserInfo);

    return (
        <ThemeProvider theme={theme}>
            <Navbar />
            <Container maxWidth={false}>
                <pre>{JSON.stringify(userInfo, null, 2)}</pre>
            </Container>
            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                }}
            >
                <img src="/bander.png" alt="" style={{ height: "200px" }} />
            </Box>
        </ThemeProvider>
    );
}

export default function DashboardPage() {
    return <DashboardContent />;
}
