import styled from "@emotion/styled";
import React, { Fragment, useEffect, useRef } from "react";
import { ChatInterface } from "./types";
import { RefreshOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { theme } from "../../styles/theme";

export const MessagesContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 840px;
    border: 1px solid #ddd;
    border-radius: 8px;
`;

export const Message = styled.div<{ $isSys?: boolean; $isUser?: boolean }>`
    margin-bottom: 8px;
    margin-top: 8px;
    padding: 8px 12px;
    width: auto;
    position: relative;
    border-radius: 20px;
    background-color: ${({ $isSys, $isUser }) => ($isSys ? "#e1e1e1" : !$isUser ? "#ffecaa" : "#007bff")};
    color: ${({ $isSys, $isUser }) => ($isSys ? "#333" : $isUser ? "#fff" : "#333")};
    margin-left: ${({ $isUser }) => (!$isUser ? "8px" : "auto")};
    margin-right: ${({ $isUser }) => ($isUser ? "8px" : "auto")};
    max-width: 85%;
`;

export const Loader = styled.div`
    text-align: center;
    font-weight: bold;
    padding: 2em;
    color: black;
`;

export const ChatContainer = ({
    chat,
    handleRegenerate,
}: {
    chat: ChatInterface;
    handleRegenerate: (idx: number) => void;
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [chat.messages]);

    return (
        <Fragment>
            <MessagesContainer ref={containerRef}>
                {chat.messages.map((message, index) => (
                    <Fragment key={index}>
                        <Message $isUser={message.isUser} $isSys={message.isSystem}>
                            <span>{message.text}</span>
                            {message.isUser && (
                                <IconButton
                                    color="primary"
                                    aria-label="delete"
                                    size="small"
                                    title="Regenerate response"
                                    sx={{
                                        position: "absolute",
                                        right: "0",
                                        bottom: "-5px",
                                        padding: 0,
                                        boxShadow: theme.shadows[5],
                                        zIndex: 2,
                                        background: theme.palette.background.paper,
                                    }}
                                    onClick={() => handleRegenerate(index)}
                                >
                                    <RefreshOutlined fontSize="inherit" />
                                </IconButton>
                            )}
                        </Message>
                    </Fragment>
                ))}
            </MessagesContainer>
        </Fragment>
    );
};
