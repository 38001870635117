import React, { useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { json, jsonParseLinter } from "@codemirror/lang-json";
import { linter } from "@codemirror/lint";
import { isObject } from "lodash-es";
import { formatJSON } from "util/json";

const linterExtension = linter(jsonParseLinter());

export function JsonEditor(props: { onChange: (...event: any[]) => void; value: string | object | null | undefined }) {
    const [isError, setIsError] = useState(false);

    const onChange = (value: string) => {
        let obj;
        try {
            obj = JSON.parse(value);
        } catch (e) {
            setIsError(true);
            return;
        }
        setIsError(false);
        props.onChange(obj);
    };

    const code = formatJSON(isObject(props.value) ? JSON.stringify(props.value) : props.value || "{}");

    return (
        <CodeMirror
            className={isError ? "code-mirror-error" : ""}
            value={code}
            extensions={[json(), linterExtension]}
            onChange={onChange}
            theme="light"
        />
    );
}
