export enum Lang {
    EN = "en",
    DE = "de",
}

export interface ProjectInterface {
    label: string;
    versionEndpoint: string;
    isProtected: boolean;
}

export interface ChatInterface {
    id: string;
    label: string;
    messages: ChatMessage[];
    createdAt: number;
}

export interface ChatMessage {
    isUser?: boolean;
    isSystem?: boolean;
    text: string;
}

export interface ChatItemInterface {
    id: string;
    label: string;
    messagesCount: number;
}

export interface BotChatMessage {
    role: "user" | "assistant";
    content: string;
}

export interface VoiceBotOptions {
    lang: Lang;
    voice: string;
    style: string;
    speed: string; // 0.1 to 2.0
    vad: string; // 0 to 1.0
}

export interface ChatBotContextType {
    options: VoiceBotOptions;
    setOptions: (options: Partial<VoiceBotOptions>) => void;
}

export interface PromptBlockInterface {
    id: string;
    title: string;
    content: string;
    label: string;
}

export interface PromptVersionInterface {
    id: string;
    label: string;
    content: string;
}

export interface PromptStateInterface {
    blocks: PromptBlockInterface[];
    savedBlocks: PromptBlockInterface[];
    status: "idle" | "loading" | "failed";
    versions: PromptVersionInterface[];
    activeVersionId: string | null;
}
